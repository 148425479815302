import React from "react"
import "./index.less"
import Link from "gatsby-link"
import LazyLoadImage from "src/components/LazyLoadImage"

class ItemList extends React.Component {
  renderItemLink = item => {
    const { canonical, season, category, colorways, title } = item.node

    const img = colorways[0].images[0].src

    return (
      <div key={canonical} className="item">
        <Link to={`/${season}/${category}/${canonical}`}>
          {img && (
            <LazyLoadImage
              wrapperClassName="item__image"
              alt={item.title}
              src={`/images/${img}`}
              width={375}
            />
          )}

          <span>{title}</span>
        </Link>
      </div>
    )
  }

  render() {
    const { items } = this.props

    return <div className="item-list">{items.map(this.renderItemLink)}</div>
  }
}

export default ItemList
