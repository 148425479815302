import React from "react"
import { graphql } from "gatsby"
import Layout from "src/components/Layout"
import SEO from "src/components/seo"
import PageHeading from "src/components/PageHeading"
import CategoryList from "src/components/CategoryList"
import ItemList from "src/components/ItemList"

class CategoryIndex extends React.Component {
  render() {
    const season = this.props.data.season.edges[0].node
    const categories = this.props.data.categories.distinct

    let items = this.props.data.items.edges
    let selectedCategory = this.props.pageContext.category

    if (!items || items.length <= 0) {
      items = this.props.data.dropDateItems.edges
      selectedCategory = "drops"
    }

    return (
      <Layout pageName="season-category">
        <SEO title={season.title} image={`/images/${season.teaser.src}`} />

        <PageHeading>{season.title}</PageHeading>

        <CategoryList
          categories={categories}
          seasonCanonical={season.canonical}
          selectedCategory={selectedCategory}
        />

        <ItemList items={items} />
      </Layout>
    )
  }
}

export default CategoryIndex

export const query = graphql`
  query CategoryQuery(
    $season: String!
    $category: String!
    $releaseDate: Date!
  ) {
    season: allSeasonsJson(filter: { canonical: { eq: $season } }) {
      edges {
        node {
          title
          canonical
          teaser {
            src
          }
        }
      }
    }

    categories: allItemsJson(filter: { season: { eq: $season } }) {
      distinct(field: category)
    }

    items: allItemsJson(
      filter: { season: { eq: $season }, category: { eq: $category } }
    ) {
      edges {
        node {
          season
          category
          title
          canonical
          colorways {
            images {
              src
            }
          }
        }
      }
    }

    dropDateItems: allItemsJson(
      filter: { season: { eq: $season }, releaseDate: { eq: $releaseDate } }
    ) {
      edges {
        node {
          season
          category
          title
          canonical
          colorways {
            images {
              src
            }
          }
        }
      }
    }
  }
`
